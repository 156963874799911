




















































.live-no-start {
    position: relative;
    flex: 1;
    display: flex;
    .container {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 42%;
        .hint {
            font-size: 18px;
            color: #070932;
            font-weight: 700;
        }
        .count-down {
            margin: 40px 0;
            font-size: 36px;
            font-weight: 700;
            color: #060111;
        }
        .start-button {
            position: relative;
            width: 140px;
            height: 50px;
            color: #fff;
            /* line-height: 50px; */
            /* text-align: center; */
            font-size: 16px;
            background-color: #4b45ff;
            border-radius: 25px;
            overflow: hidden;
            z-index: 999;
            border: none;
            /* cursor: pointer; */
            .mask {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transition: all 0.3s;
                background-color: transparent;
            }
            &:hover .mask {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}
